import styled from '@emotion/styled';
import {colors} from '../theme';

export const DropdownContainer = styled.div`
    .dropdown-ellipsis {
        button {
            background-color: ${colors.white[100]};
            border: 1px solid ${colors.gray[200]};
            border-radius: 50%;
            width: 38px;
            height: 38px;
            position: absolute;
            z-index: 40;
            right: -3px;
            top: -18px;

            &:focus {
                box-shadow: none;
            }
            &:hover {
                color: inherit;
            }

            &.dark {
                background-color: ${colors.gray[650]};
                color: ${colors.white[100]};
            }
        }
        &.default {
            position: relative;
            margin-left: 16px;

            button {
                position: relative;
                top: 0;
            }
        }
        &.center {
            position: absolute;
            top: 50%;
            right: 26px;
        }

        &.static {
            position: relative;
            right: 10px;
            top: 17px;
        }

        &.top {
            position: absolute;
            right: 11px;
            top: 34px;
        }

        .dropdown-menu {
            z-index: 2001;
        }
    }
`;
