import * as React from 'react';
import {CSSProperties} from 'react';
import {Dropdown} from 'react-bootstrap';
import {faAngleDown, faEllipsisV, faPlus} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {DropdownContainer} from './ContextMenu.styles';
import {ToolTip} from '../Tooltip/Tooltip';

interface ContextMenuItem {
    disabled?: boolean;
    divider?: boolean;
    hidden?: boolean;
    href?: string;
    name?: string;
    onClick?: () => void;
    tooltip?: string;
}

export type ContextPosition = `center` | `static` | `default` | `top`;

export interface ContextMenuProps {
    ariaLabel?: string;
    className?: string;
    dark?: boolean;
    darkSquare?: boolean;
    dataE2E?: string;
    index: number | string;
    items: ContextMenuItem[];
    options?: {dark?: boolean; downIcon?: boolean; plusIcon?: boolean; alignRight?: boolean};
    position?: ContextPosition;
    rounded?: boolean;
    style?: CSSProperties;
}

export const ContextMenu = ({
    ariaLabel,
    className,
    darkSquare = false,
    dataE2E,
    index,
    items,
    options = {dark: false, downIcon: false, plusIcon: false, alignRight: true},
    position,
    rounded = true,
    style,
}: ContextMenuProps) => {
    /**
     * Template
     */
    return (
        <>
            {items.find(({hidden}) => !hidden) && (
                <DropdownContainer>
                    <Dropdown
                        alignRight={options.alignRight}
                        className={`${
                            rounded ? 'dropdown-ellipsis' : 'tw-h-[38px] tw-w-[38px] tw-items-center tw-text-center tw-rounded tw-border'
                        } ${position ? position : 'default'}${className ? ' ' + className : ''}`}
                        data-e2e={dataE2E}
                        style={style}
                    >
                        <Dropdown.Toggle
                            aria-label={ariaLabel}
                            className={`${
                                darkSquare
                                    ? '!tw-bg-gray-600 hover:!tw-bg-gray-700 !tw-text-white tw-w-[38px] tw-text-center tw-items-center '
                                    : 'gray-650'
                            } mr-n2 text-decoration-none no-after d-flex align-items-center justify-content-center ${
                                options?.dark && 'dark'
                            }`}
                            id={`dropdown-basic_${index}`}
                            variant="link"
                        >
                            {options.downIcon ? (
                                <FontAwesomeIcon
                                    icon={faAngleDown}
                                    size="xl"
                                    style={{marginTop: 2}}
                                    suppressHydrationWarning
                                />
                            ) : options.plusIcon ? (
                                <FontAwesomeIcon
                                    className={`tw-text-white !tw-bg-gray-650 hover:!tw-bg-gray-700`}
                                    icon={faPlus}
                                    size="sm"
                                    suppressHydrationWarning
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className={`tw-text-gray-650 hover:tw-text-gray-650`}
                                    icon={faEllipsisV}
                                    size="xl"
                                    suppressHydrationWarning
                                />
                            )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {items
                                .filter((item) => !item.hidden)
                                .map((item, key) =>
                                    item.divider ? (
                                        <Dropdown.Divider key={key} />
                                    ) : item.tooltip ? (
                                        <ToolTip
                                            id={key.toString()}
                                            key={key}
                                            title={item.tooltip}
                                        >
                                            <Dropdown.Item
                                                disabled={item.disabled}
                                                href={item.href || `#`}
                                                onClick={item.onClick}
                                            >
                                                {item.name}
                                            </Dropdown.Item>
                                        </ToolTip>
                                    ) : (
                                        <Dropdown.Item
                                            className="tw-py-1"
                                            disabled={item.disabled}
                                            href={item.href || `#`}
                                            key={key}
                                            onClick={item.onClick}
                                        >
                                            {item.name}
                                        </Dropdown.Item>
                                    ),
                                )}
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
            )}
        </>
    );
};
